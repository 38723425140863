import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search) => {
  try {
    let url = `/payment/outward-payment?`;

    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }
    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const getAllReportsData = async (page, per_page, search) => {
  try {
    let url = `/payment/outward-payment/reports?`;

    if (page) {
      url += `&page=${page}`;
    }

    

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const OutwardAccepted = async (data) => {
  try {
    return await postData(`/payment/outward-payment/accepted`, data);
  } catch (error) {
    console.error(error);
  }
};

export const OutwardRejected = async (data) => {
  try {
    return await postData(`/payment/outward-payment/rejected`, data);
  } catch (error) {
    console.error(error);
  }
};

export const OutwardMultiple = async (data) => {
  try {
    return await postData(`/payment/outward-payment/multiple`, data);
  } catch (error) {
    console.error(error);
  }
};
